/* eslint object-shorthand: 0 */
// Values coming from Webpack DefinePlugin
// TODO: Find a better way to expose env vars
// see: https://git.naspersclassifieds.com/olxeu/delivery/fm-delivery/issues/5
export default {
  FEMS_ENV: process.env.FEMS_ENV,
  PUBLIC_URL: `${PUBLIC_URL}/`,
  ROCK_LITE: process.env.ROCK_LITE,
  RO_ROCK_BASE: process.env.RO_ROCK_BASE,
  SENTRY_DSN: process.env.SENTRY_DSN,
  PWA_DESKTOP_DOMAIN: process.env.PWA_DESKTOP_DOMAIN,
  PWA_MOBILE_DOMAIN: process.env.PWA_MOBILE_DOMAIN,
};
