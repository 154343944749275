/**
 * Decorates a function that returns a promise, rejecting the promise if
 * the function takes more than `timeout` milliseconds to resolve
 * @param func the function returning a promise
 * @param timeout the number of milliseconds to wait before rejecting
 */
const withTimeout = (func, timeout = 10000) => (...args) => new Promise(
  (resolve, reject) => {
    func(...args)
      .then(resolve)
      .catch(reject);
    setTimeout(() => {
      reject(new Error(`${func.name}: ${timeout / 1000} secs timeout reached`));
    }, timeout);
  },
);

export default withTimeout;
