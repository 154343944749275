<script>
  import { onMount, setContext } from 'svelte';
  import bemClassName from '@olx-fm/ui-toolkit/utils/bemClassName';
  import { buildValidator, is } from 'Shared/validate';
  import { MOBILE, DESKTOP } from 'Shared/const/viewport';
  import { FRAGMENT_KEY } from '../const';
  import initContext from '../initContext';

  // props
  export let viewport = MOBILE;
  export let accessToken = '';
  export let useCDN = false;

  const {
    api,
    stores: { orders },
  } = initContext(setContext, { accessToken });

  // validate props
  const validate = buildValidator(FRAGMENT_KEY);
  $: viewport = validate(viewport, 'viewport', is.oneOf([MOBILE, DESKTOP]));
  $: accessToken = validate(accessToken, 'accessToken', is.string, is.truthy);

  // helper
  const cn = bemClassName('PendingOrdersBadge');

  // mount
  onMount(() => api.getPendingOrders({ accessToken, viewport, useCDN }));
</script>


{#if $orders.data && $orders.data.pending }
  <div class={cn('', `--${viewport}`)}>
    { $orders.data.pending || 0 }
  </div>
{:else}
  <span />
{/if}

<style type="text/scss">
  @use 'ui-toolkit/css/text';
  @use 'ui-toolkit/css/colors';
  .PendingOrdersBadge {
    @extend %setup-font-mweb; // mobile first
    @extend %text-type-p6;
    @extend %text-dark;
    background: colors.$yellow-primary;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    box-sizing: border-box;

    &--desktop {
      @extend %setup-font-atlas;
    }
  }
</style>
