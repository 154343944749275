export const TRANSACTION_STATUS = {
  CONFIRMED: 'CONFIRMED',
  DELIVERED: 'DELIVERED',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  MODERATED: 'MODERATED',
  NEW: 'NEW',
  PAID: 'PAID',
  PAYOUT: 'PAYOUT',
  PENDING: 'PENDING',
  REFUND: 'REFUND',
  REJECTED: 'REJECTED',
  SHIPMENT_CREATED: 'SHIPMENT_CREATED',
  SHIPMENT_REQUESTED: 'SHIPMENT_REQUESTED',
};

export const VIEWPORT = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};
