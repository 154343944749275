import { logger } from './Sentry';

/**
 * Inject Error Logging
 * @param caller
 * @param fragmentKey
 * @returns {Function}
 */
export default (caller, fragmentKey) => (...args) => {
  // TODO: fragmentKey could be obtained on build time
  try {
    return caller(...args);
  } catch (err) {
    logger(fragmentKey)(err);
    throw err;
  }
};
