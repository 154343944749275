import env from './env';


const SENTRY_IFRAME_ID = 'sentry-provider';

/**
 * Instructs the isolated Sentry to run Sentry.init with the Delivery DSN and environment
 * @param {HTMLElement} sentryIframe
 */
const initSentry = (sentryIframe) => {
  sentryIframe.contentWindow.postMessage({
    type: 'fm:sentry',
    action: 'init',
    args: {
      dsn: env.SENTRY_DSN,
      environment: env.FEMS_ENV,
    },
  }, '*');
};


/**
 * Returns the iframe only when it's ready to receive messages
 * @param {Promise<HTMLElement>} sentryIframe
 */
const whenReady = (sentryIframe) => new Promise((resolve) => {
  const interval = setInterval(() => {
    sentryIframe.contentWindow.postMessage({ type: 'fm:sentry', action: 'ping' }, '*');
  }, 300);
  window.addEventListener('message', (ev) => {
    if (ev.data.type !== 'fm:sentry' || ev.data.action !== 'pong') return;
    clearInterval(interval);
    resolve(sentryIframe);
  });
});


/**
 * Returns a Promise fulfilled with the isolated Sentry iframe.
 * If the iframe is created for the first time, the Promise is resolved only
 * when the iframe is ready to receive captureException events.
 * @return {Promise<HTMLElement>} the Sentry provider iframe
 */
const getSentryIframe = () => new Promise((resolve) => {
  let sentryIframe = document.getElementById(SENTRY_IFRAME_ID);
  if (sentryIframe) {
    whenReady(sentryIframe).then(() => resolve(sentryIframe));
  } else {
    sentryIframe = document.createElement('iframe');
    sentryIframe.id = SENTRY_IFRAME_ID;
    sentryIframe.setAttribute(
      'src',
      `${process.env.PUBLIC_URL_DOMAIN}${process.env.PUBLIC_URL_PATH}/shared/assets/sentryProvider.html`,
    );
    sentryIframe.style = 'display:none;';
    document.body.append(sentryIframe);
    whenReady(sentryIframe).then(() => {
      initSentry(sentryIframe);
      resolve(sentryIframe);
    });
  }
});


/**
 * Log a given Error to sentry, but only for Staging and Production
 * everything else will be logged to console.
 * @param fragmentKey
 * @returns {Function}
 */
export const logger = (fragmentKey) => (error) => {
  if (error.skipMonitoring) return;
  if (['production', 'staging'].includes(env.FEMS_ENV)) {
    const { extras, tags } = error.context || {};
    getSentryIframe().then((iframe) => {
      iframe.contentWindow.postMessage({
        type: 'fm:sentry',
        action: 'captureException',
        error,
        fragmentKey,
        extras,
        tags,
      }, '*');
    });
  } else {
    // eslint-disable-next-line no-console
    console.error('SENTRY:', error);
  }
};
