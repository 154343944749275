import { apiWithTimeout, getXClient } from '../service';

/**
 * Fetches Pending Orders counter
 * @see https://git.naspersclassifieds.com/olxeu/delivery/ro-rock/-/blob/master/src/main/resources/openapi.yaml#
 *
 * @name getTransactionPending
 * @param accessToken
 * @returns {Promise<T>}
 */

export default ({ accessToken, viewport, useCDN }) => apiWithTimeout(
  '/transaction/pending',
  {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-client': getXClient(viewport),
    },
  },
  { useCDN },
);
