const projectNamespace = '';

export default (component) => (element = '', ...modifiers) => {
  const className = `${component}${element || ''}`;
  if (modifiers.length > 0) {
    return `${projectNamespace} ${className} ${modifiers
      .filter((val) => !!val)
      .map((mod) => className + mod)
      .join(' ')}`;
  }
  return `${projectNamespace} ${className}`;
};
