import customFetch from 'Shared/utils/customFetch';
import withTimeout from 'Shared/utils/withTimeout';
import { VIEWPORT } from './const';

const { RO_ROCK_BASE } = process.env;
const { RO_ROCK_BASE_CDN } = process.env;
export const SERVICE_NAME = 'RORock';
export const SERVICE_TIMEOUT_MS = 10000;

/**
 * Enhanced Fetch for Romania Rock
 * @param url
 * @param params
 * @param options
 * @returns {Promise<T>}
 */
export const api = (url, params = {}, options = {}) => customFetch(
  `${options.useCDN ? RO_ROCK_BASE_CDN : RO_ROCK_BASE}${url}`,
  {
    ...params,
    mode: 'cors',
    headers: {
      ...(params.headers || {}),
      'content-type': 'application/json',
      'access-control-request-headers': 'authorization,content-type',
    },
  },
  { ...options, serviceName: SERVICE_NAME },
);

export const apiWithTimeout = withTimeout(api, SERVICE_TIMEOUT_MS);

/**
 * Recursively traverses the object and returns a modified copy:
 * - strings are trimmed
 * - empty strings (after trimming) are omitted
 * @param {object} obj the object to sanitize
 */
export const sanitize = (obj) => (
  Object.keys(obj).reduce((acc, prop) => {
    if (obj[prop] && typeof obj[prop] === 'object') {
      acc[prop] = sanitize(obj[prop]);
    } else if (typeof obj[prop] === 'string') {
      const trimmed = obj[prop].trim();
      if (trimmed.length) {
        acc[prop] = trimmed;
      }
    } else {
      acc[prop] = obj[prop];
    }
    return acc;
  }, {})
);

export const getXClient = (viewport) => (
  viewport === VIEWPORT.MOBILE ? 'mweb' : 'desktop'
);
