import ServiceError from 'Shared/error/ServiceError';
/**
 * Enhanced Fetch
 * @param url
 * @param params
 * @param serialize
 * @param serviceName
 * @returns {Promise<T>}
 */
export default (url, params = {}, { serializeResponse = true, serviceName = 'N/A' } = {}) => {
  const errorHandler = (error) => Promise.reject(
    error instanceof ServiceError
      ? error
      : new ServiceError(error, serviceName, { url, method: params.method }),
  );

  const buildErrorText = (txt, res) => {
    let msg = `Service: '${serviceName}'`;
    if (res && res.status) msg += ` \nStatus: ${res.status}`;
    if (res && res.statusText) msg += ` \nStatus text: ${res.statusText}`;
    if (txt) msg += ` \nText: ${txt}`;
    return msg;
  };

  return fetch(url, params)
    .then((res) => {
      if (res.ok) {
        if (serializeResponse) {
          if (typeof res.text === 'function') {
            return res.text().then((txt) => {
              try {
                return (res.status === 204) ? '' : JSON.parse(txt);
              } catch (e) {
                return errorHandler(buildErrorText(`Not a JSON: ${txt}`, res));
              }
            });
          }
          return errorHandler(buildErrorText(`Response does not support text function: ${res}`, res));
        }
        return res;
      }
      if (typeof res.text === 'function') return res.text().then((txt) => errorHandler(buildErrorText(txt, res)));
      if (typeof res === 'string') {
        try {
          return JSON.parse(res);
        } catch (e) {
          return errorHandler(buildErrorText(res));
        }
      }
      return errorHandler(buildErrorText(`Response has wrong type: '${typeof res}'`));
    })
    .catch(errorHandler);
};
