import { logger } from './Sentry';


const ofType = (type) => ({
  // eslint-disable-next-line valid-typeof
  check: (v) => (typeof v === type),
  message: `must be a ${type}`,
});

export const is = {
  number: ofType('number'),
  string: ofType('string'),
  boolean: ofType('boolean'),
  array: {
    check: (v) => Array.isArray(v),
    message: 'must be array',
  },
  truthy: {
    check: (v) => (!!v),
    message: 'must be truthy',
  },
  falsy: {
    check: (v) => (!v),
    message: 'must be falsy',
  },
  between: (start, end) => ({
    check: (v) => (v >= start && v <= end),
    message: `must be between ${start} and ${end}`,
  }),
  matching: (re) => ({
    check: (v) => (re.test(v)),
    message: `must match regular expression ${re}`,
  }),
  oneOf: (arrValues) => ({
    check: (v) => (!!arrValues.filter((x) => x === v).length),
    message: `must be one of: [${arrValues.join(', ')}].`,
  }),
};


// eslint-disable-next-line no-console
export const buildValidator = (svcName = 'fm', logFunc = console.warn) => {
  const oldProps = {};
  return (value, name, ...assertions) => {
    if (!Object.prototype.hasOwnProperty.call(oldProps, name)) {
      oldProps[name] = value;
    }
    let err = false;
    assertions.forEach((assertion) => {
      if (!assertion.check(value)) {
        err = true;
        logFunc(`${svcName}: '${name}' ${assertion.message}, ${value} received.`);
      }
    });

    if (err) {
      return oldProps[name];
    }
    oldProps[name] = value;
    return value;
  };
};

export const buildSentryValidator = (fragmentKey) => {
  if (!fragmentKey) throw new Error('fragmentKey is required');
  const logInSentry = logger(fragmentKey);
  return buildValidator(fragmentKey, (message) => {
    logInSentry(new Error(message));
  });
};
