import { writable } from 'svelte/store';

const DEFAULT = {
  data: null,
  error: null,
  loading: false,
  ok: false,
};

export default (asyncFunc) => {
  const store = writable(DEFAULT);
  return [
    store,
    (...args) => {
      store.set({ ...DEFAULT, loading: true });
      return asyncFunc(...args)
        .then((data) => {
          store.set({ ...DEFAULT, data, ok: true });
          return data;
        })
        .catch((error) => {
          store.set({ ...DEFAULT, error });
          throw error;
        });
    },
  ];
};
