import { writable } from 'svelte/store';
import getFuncInterfaceStore from 'Shared/utils/getFuncInterfaceStore';

import fetchPendingOrders from 'Shared/service/ro-rock/transaction/getTransactionPending';

export default (setContext, { accessToken }) => {
  const [orders, getPendingOrders] = getFuncInterfaceStore(fetchPendingOrders);

  const context = {
    stores: {
      orders,
      accessToken: writable(accessToken),
    },
    api: {
      getPendingOrders,
    },
  };

  setContext(undefined, context);
  return context;
};
