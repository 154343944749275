/**
 * Service Errors
 */
export default class ServiceError extends Error {
  constructor(error, name = 'N/A', request = {}) {
    super(error.description || error.message || error);
    this.name = 'ServiceError';
    this.code = error.code;
    this.context = {
      tags: { 'fm.origin': name },
      extras: {
        source: error,
        request,
      },
    };
  }
}
